<template>
    <div class="d-flex flex-column vh-100">
        <div class="container py-5 my-0 my-sm-5 px-4 not-layout">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                    <div class="row mb-2">
                        <div class="col-12">
                            <div class="row align-items-center mb-0 pb-5 mb-sm-2">
                                <div class="col-4 text-left">
                                    <div class="logo-wrapper">
                                        <img :src="$store.getters['dashboard/getImage']('logo.png')" height="130"/>
                                    </div>
                                </div>
                                <div class="col-4 text-center">
                                    <h4 class="mb-0">{{ $t('epayment') }}</h4>
                                </div>
                                <div class="col-4 text-right">
                                    <user-button style="float:left" :nameSurname="epaymentUser.name + ' ' + epaymentUser.surname" :isLogin="true" @epaymentLogout="logout"></user-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-tabs content-class="py-3 position-relative" v-model="tabIndex">
                        <div class="tabs-bottom-line"></div>
                        <b-tab @click="clearDebt()">
                            <template #title><i class="ri-price-tag-line top-plus-2 mr-2"></i>{{ $t('debts') }}</template>

                            <!-- Loading -->
                            <div class="spinner over text-center" v-if="debtsLoading" style="min-height: 300px">
                                <b-spinner label="Spinning" variant="primary"></b-spinner>
                            </div>
                            <div v-else>
                                <div class="text-center w-100" v-if="
                                !((debts.payment_plans && debts.payment_plans.length)
                                || (debts.payments && debts.payments.length)
                                || (debts.housing && debts.housing.length)
                                || (debts.other_payments && debts.other_payments.length))">
                                    <div class="mb-5">
                                        <i class="ri-calculator-line ri-5x text-muted"></i>
                                    </div>
                                    <h4 class="mb-4 text-black-50">{{ $t('you_dont_have_a_payment_plan') }}</h4>
                                </div>
                            </div>

                            <!-- Debts -->
                            <div class="row" v-show="!debtsLoading">
                                <div class="col-12 col-md-10 col-lg-10 offset-md-1 offset-lg-1">
                                    <b-card no-body class="mb-3 border-0" v-for="(item1, key1) in debts.payment_plans" :key="'accordion1-'+key1">
                                        <b-card-header header-tag="header" class="px-0 py-1 m-0 border-0 bg-transparent" role="tab">
                                            <b-button block v-b-toggle="'accordion1-'+key1"
                                                      variant="secondary bg-gray-100 text-dark h-auto font-weight-medium line-height-normal py-2">
                                                <div class="d-block d-md-flex justify-content-between w-100">
                                                    <div class="text-left mb-1 mb-md-0 pr-3 d-flex align-items-center">
                                                        {{ item1.program.name }}
                                                        {{ item1.academic_year ? ' - ' + item1.academic_year : '' }} -
                                                        {{ item1.product_name.toUpper() }}
                                                        {{item1.explanation?' ('+item1.explanation.toUpper()+')':''}}
                                                    </div>
                                                    <div class="text-left font-weight-bold white-space-nowrap h6 mb-0">
                                                        {{ item1.cash_price | formatToCurrency(item1.currency) }}
                                                    </div>
                                                </div>
                                            </b-button>
                                        </b-card-header>
                                        <b-collapse :id="'accordion1-'+key1" accordion="my-accordion" role="tabpanel">
                                            <b-card no-body class="mb-3 border-0">
                                                <b-card-body class="px-0">
                                                    <div class="border rounded p-3 mb-3">
                                                        <b-row>
                                                            <b-col class="d-flex flex-column justify-content-center">
                                                                <h4 class="text-center mb-0">{{ item1.debt | formatToCurrency(item1.currency) }}</h4>
                                                            </b-col>
                                                            <b-col>
                                                                <b-button type="button" variant="primary btn-block"
                                                                          @click="paymentSetVars('payment_plan', item1.uuid,'advance')">
                                                                    {{ $t('pay') }}
                                                                </b-button>
                                                            </b-col>
                                                            <b-col v-if="cart">
                                                                <b-button v-if="!isInTheCart(item1.uuid)" type="button" variant="primary btn-block"
                                                                          @click="addToCart(item1.uuid)">
                                                                    {{ $t('add_to_cart') }}
                                                                </b-button>
                                                                <b-button v-else type="button" variant="danger btn-block"
                                                                          @click="deleteFromCart(item1.uuid)">
                                                                    {{ $t('delete_from_cart') }}
                                                                </b-button>
                                                            </b-col>
                                                        </b-row>
                                                    </div>
                                                </b-card-body>
                                            </b-card>
                                        </b-collapse>
                                    </b-card>

                                    <!-- Payments -->
                                    <b-card no-body class="mb-3 border-0" v-for="(item2, key2) in debts.payments" :key="'accordion2-'+key2">
                                        <b-card-header header-tag="header" class="px-0 py-1 m-0 border-0 bg-transparent" role="tab">
                                            <b-button block v-b-toggle="'accordion2-'+key2" variant="secondary bg-gray-100 text-dark h-auto font-weight-medium line-height-normal py-2">
                                                <div class="d-block d-md-flex justify-content-between w-100">
                                                    <div class="text-left text-lg-center mb-1 mb-lg-0">
                                                        {{ item2.program.name }}
                                                        {{ item2.academic_year ? ' - ' + item2.academic_year : '' }}
                                                        {{ item2.product_name.toUpper() }}
                                                        - {{ item2.due_date }}
                                                    </div>
                                                    <div class="text-left text-lg-center font-weight-bold">
                                                        {{ item2.amount | formatToCurrency(item2.currency) }}
                                                    </div>
                                                </div>
                                            </b-button>
                                        </b-card-header>
                                        <b-collapse :id="'accordion2-'+key2" accordion="my-accordion" role="tabpanel">
                                            <b-card-body class="px-0 py-3">
                                                <div class="border rounded p-3 mb-3">
                                                    <b-row>
                                                        <b-col class="d-flex flex-column justify-content-center">
                                                            <h4 class="text-center mb-0">{{ item2.amount | formatToCurrency(item2.currency) }}</h4>
                                                        </b-col>
                                                        <b-col>
                                                            <b-button type="button" variant="primary btn-block"
                                                                      @click="paymentSetVars('payment', item2.uuid,'advance')">
                                                                {{ $t('pay') }}
                                                            </b-button>
                                                        </b-col>
                                                        <b-col v-if="cart">
                                                            <b-button v-if="!isInTheCart(item2.uuid)" type="button" variant="primary btn-block"
                                                                      @click="addToCart(item2.uuid)">
                                                                {{ $t('add_to_cart') }}
                                                            </b-button>
                                                            <b-button v-else type="button" variant="danger btn-block"
                                                                      @click="deleteFromCart(item2.uuid)">
                                                                {{ $t('delete_from_cart') }}
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>

                                    <!-- Other Payments -->
                                    <b-card no-body class="mb-3 border-0" v-for="(item2, key2) in debts.other_payments" :key="'accordion3-'+key2">
                                        <b-card-header header-tag="header" class="px-0 py-1 m-0 border-0 bg-transparent" role="tab">
                                            <b-button block v-b-toggle="'accordion3-'+key2" variant="secondary bg-gray-100 text-dark h-auto font-weight-medium line-height-normal py-2">
                                                <div class="d-block d-md-flex justify-content-between w-100">
                                                    <div class="text-left text-lg-center mb-1 mb-lg-0">
                                                        {{ item2.program.name }}
                                                        {{ item2.academic_year ? ' - ' + item2.academic_year : '' }}
                                                        {{ item2.product_name.toUpper() }}
                                                    </div>
                                                    <div class="text-left text-lg-center font-weight-bold">
                                                        {{ item2.amount | formatToCurrency(item2.currency) }}
                                                    </div>
                                                </div>
                                            </b-button>
                                        </b-card-header>
                                        <b-collapse :id="'accordion3-'+key2" accordion="my-accordion" role="tabpanel">
                                            <b-card-body class="px-0 py-3">
                                                <div class="border rounded p-3 mb-3">
                                                    <b-row>
                                                        <b-col class="d-flex flex-column justify-content-center">
                                                            <h4 class="text-center mb-0">{{ item2.amount | formatToCurrency(item2.currency) }}</h4>
                                                        </b-col>
                                                        <b-col>
                                                            <b-button type="button" variant="primary btn-block"
                                                                      @click="paymentSetVars('other_payment', item2.uuid,'advance')">
                                                                {{ $t('pay') }}
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>

                                    <b-card no-body class="mb-3 border-0" v-for="(item4, key4) in debts.housing" :key="'accordion4-'+key4">
                                        <b-card-header header-tag="header" class="px-0 py-1 m-0 border-0 bg-transparent" role="tab">
                                            <b-button block v-b-toggle="'accordion4-'+key4" variant="secondary bg-gray-100 text-dark h-auto font-weight-medium line-height-normal py-2">
                                                <div class="d-block d-md-flex justify-content-between w-100">
                                                    <div class="text-left text-lg-center mb-1 mb-lg-0">
                                                        {{ item4.product.name.toUpper() }}
                                                        - {{ item4.due_date }}
                                                    </div>
                                                    <div class="text-left text-lg-center font-weight-bold">
                                                        {{ item4.amount | formatToCurrency(item4.currency) }}
                                                    </div>
                                                </div>
                                            </b-button>
                                        </b-card-header>
                                        <b-collapse :id="'accordion4-'+key4" accordion="my-accordion" role="tabpanel">
                                            <b-card-body class="px-0 py-3">
                                                <div class="border rounded p-3 mb-3">
                                                    <b-row>
                                                        <b-col class="d-flex flex-column justify-content-center">
                                                            <h4 class="text-center mb-0">{{ item4.amount | formatToCurrency(item4.currency) }}</h4>
                                                        </b-col>
                                                        <b-col>
                                                            <b-button type="button" variant="primary btn-block"
                                                                      @click="paymentSetVars('housing', item4.uuid,'advance')">
                                                                {{ $t('pay') }}
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>

                                </div>
                                <div class="col-12 col-md-10 col-lg-10 offset-md-1 offset-lg-1" v-if="cart && cart.total">
                                    <b-button type="button" variant="primary btn-block" @click="tabIndex=1">
                                        <div class="d-flex justify-content-between w-100">
                                            <div>{{ $t('pay').toUpper() }}</div>
                                            <div v-if="paymentFormShowAmount && paymentDebt.currency">
                                                {{ paymentFormShowAmount | formatToCurrency(paymentDebt.currency) }}
                                            </div>
                                        </div>
                                    </b-button>
                                </div>
                            </div>
                        </b-tab>

                        <b-tab :disabled="paymentDebtId ? false:true">
                            <template #title><i class="ri-bank-card-line top-plus-2 mr-2"></i>{{ $t('pay') }}</template>
                            <div class="row">
                                <div class="col-12">
                                    <div v-if="paymentFormMaxAmount===0 && paymentFormSumAmount>0">
                                        <div class="text-center py-5 my-5 fadeIn d-block h-auto show">
                                            <div class="mb-5">
                                                <i class="ri-checkbox-circle-line ri-6x text-success"></i>
                                            </div>
                                            <h4>{{ $t('your_payment_transaction_has_been_successfully_completed') }}</h4>
                                        </div>
                                    </div>
                                    <ValidationObserver ref="payForm">
                                        <div class="row" v-if="paymentFormMaxAmount>0">
                                            <div class="col-12" v-if="paymentDataType!=='cart'">
                                                <h5 class="mb-0">
                                                    <span v-if="paymentDebt.program">
                                                        {{ paymentDebt.program.name.toUpper() + ' - '}}
                                                    </span>
                                                    <span v-if="paymentDebt.academic_year">
                                                        {{ paymentDebt.academic_year + ' - '}}
                                                    </span>
                                                    <span v-if="paymentDebt.product_name">
                                                        {{ paymentDebt.product_name.toUpper() }}
                                                        {{ paymentDebt.explanation ? '('+paymentDebt.explanation+')':'' }}
                                                    </span>
                                                    <span v-else-if="paymentDebt.product && paymentDebt.product.name">
                                                        {{ paymentDebt.product.name.toUpper() }} - {{paymentDebt.due_date}}
                                                    </span>
                                                </h5>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <div class="row">
                                                    <div class="col-6 col-md-12" v-if="paymentFormPrice!=null && paymentDebt.currency">
                                                        <b-form-group :label="$t('total_amount')" >
                                                            <div><b>{{ paymentFormPrice | formatToCurrency(paymentDebt.currency) }}</b></div>
                                                        </b-form-group>
                                                    </div>
                                                    <div class="col-6 col-md-12" v-if="paymentFormSumAmount!=null && paymentDebt.currency">
                                                        <b-form-group :label="$t('amount_paid')" >
                                                            <div><b>{{ paymentFormSumAmount | formatToCurrency(paymentDebt.currency) }}</b></div>
                                                        </b-form-group>
                                                    </div>
                                                    <div class="col-12">
                                                        <ValidationProvider name="payment_total" rules="required" v-slot="{valid, errors}">
                                                            <b-form-group :label="$t('payment_total')">
                                                                <b-form-input type="number"
                                                                              v-model.sync="paymentFormAmount"
                                                                              min="0.1"
                                                                              :max="Math.ceil(parseFloat(paymentFormMaxAmount))"
                                                                              @focusout="paymentFormAmountCalculate"
                                                                              v-check-min-max-value
                                                                              :readonly="paymentFormAmountIsReadOnly"
                                                                              :state="errors[0] ? false : (valid ? true : null)">
                                                                </b-form-input>
                                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-6 col-md-12" v-if="paymentDebt.currency">
                                                        <b-form-group :label="$t('remaining_amount')" >
                                                            <div><b>{{ paymentFormRemainingAmount | formatToCurrency(paymentDebt.currency) }}</b></div>
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-8">
                                                <div class="row">
                                                    <div class="col-12" v-if="allowPaypal">
                                                        <b-button type="button" variant="primary btn-block" style="background-color: #FCBB32;color:#2C2E2F" @click="paypal">
                                                            <div class="d-flex justify-content-between w-100">
                                                                <div>{{ $t('pay_with_paypal').toUpper() }}</div>
                                                                <div v-if="paymentFormShowAmount && paymentDebt.currency">
                                                                    {{ paymentFormShowAmount | formatToCurrency(paymentDebt.currency) }}
                                                                </div>
                                                            </div>
                                                        </b-button>
                                                    </div>
                                                    <div class="col-12 mt-3" v-if="allowPaypal">
                                                        <hr>
                                                        <div style="position: absolute;z-index: 1000;top: 3px;left: 46%;width: 8%;background-color: #ffffff;text-align: center;">or</div>
                                                    </div>
                                                    <div class="col-12 mt-3" v-if="allowPaypal">
                                                        <h5 class="text-center">{{$t('pay_with_credit_card').toUpper()}}</h5>
                                                    </div>
                                                    <div class="col-12">
                                                          <ValidationProvider name="card_number" rules="required" v-slot="{valid, errors}">
                                                            <b-form-group :label="$t('card_number')" class="position-relative">
                                                                <b-form-input type="text"
                                                                              v-model="paymentFormCreditCardNumber"
                                                                              :state="errors[0] ? false : (valid ? true : null)" v-mask="'#### #### #### ####'"
                                                                              autocomplete="off"
                                                                >
                                                                </b-form-input>
                                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-12">
                                                        <ValidationProvider name="card_owner" rules="required" v-slot="{valid, errors}">
                                                            <b-form-group :label="$t('card_owner')">
                                                                <b-form-input type="text"
                                                                              v-model="paymentFormCardHolderName"
                                                                              :state="errors[0] ? false : (valid ? true : null)"
                                                                              autocomplate="off"
                                                                              v-uppercase
                                                                >
                                                                </b-form-input>
                                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-4">
                                                        <ValidationProvider name="expiry_month" rules="required" v-slot="{valid, errors}">
                                                            <fieldset class="form-group">
                                                                <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">{{ $t('month') }}</legend>
                                                                <multiselect
                                                                    :placeholder="$t('select')"
                                                                    v-model="paymentFormExpiryMonth"
                                                                    :options="paymentFormMonthOptions"
                                                                    :select-label="''"
                                                                    :selected-label="''"
                                                                    :deselect-label="''"
                                                                    :searchable="false"
                                                                    :allow-empty="true"
                                                                    :class="errors[0] ? 'is-invalid' : (valid ? 'is-valid' : null)"
                                                                >
                                                                </multiselect>
                                                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                            </fieldset>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-4">
                                                        <ValidationProvider name="expiry_year" rules="required" v-slot="{valid, errors}">
                                                            <fieldset class="form-group">
                                                                <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">{{ $t('year') }}</legend>
                                                                <multiselect
                                                                    :placeholder="$t('select')"
                                                                    v-model="paymentFormExpiryYear"
                                                                    :options="paymentFormYearOptions"
                                                                    :select-label="''"
                                                                    :selected-label="''"
                                                                    :deselect-label="''"
                                                                    :searchable="false"
                                                                    :class="errors[0] ? 'is-invalid' : (valid ? 'is-valid' : null)"
                                                                >
                                                                </multiselect>
                                                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                            </fieldset>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-4">
                                                        <ValidationProvider name="cvc" rules="required" v-slot="{valid, errors}">
                                                            <b-form-group label="CVC">
                                                                <b-form-input type="text"
                                                                              v-model="paymentFormCvv"
                                                                              :state="errors[0] ? false : (valid ? true : null)"
                                                                              v-mask="'####'">
                                                                </b-form-input>
                                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>

                                                    <div class="col-12">
                                                        <b-button type="button" variant="primary btn-block" @click="pay">
                                                            <div class="d-flex justify-content-between w-100">
                                                                <div>{{ $t('pay').toUpper() }}</div>
                                                                <div v-if="paymentFormShowAmount && paymentDebt.currency">
                                                                    {{ paymentFormShowAmount | formatToCurrency(paymentDebt.currency) }}
                                                                </div>
                                                            </div>
                                                        </b-button>
                                                    </div>
                                                    <div class="col-12">
                                                        <b-alert variant="info" class="text-center mt-3" show>
                                                           Zelle Account: Zellebau@bau.edu
                                                        </b-alert>
                                                        <b-alert variant="warning" class="text-center mt-3" show>
                                                            If you need assistance with the payment process, please contact <a href="mailto:finance@bau.edu">finance@bau.edu</a> or visit our office on the 9th floor.
                                                        </b-alert>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ValidationObserver>
                                </div>
                            </div>
                        </b-tab>

                        <b-tab v-if="debts && debts.done_payments">
                            <template #title><i class="ri-file-download-line top-plus-2 mr-2"></i>{{ $t('receipts') }}</template>
                            <div class="row">

                                <div class="col-12">
                                    <table class="table table-bordered">
                                        <tr>
                                            <th>{{$t('date')}}</th>
                                            <th>{{$t('amount')}}</th>
                                            <th class="w-40"></th>
                                        </tr>
                                        <tr v-for="payment in debts.done_payments">
                                            <td>{{payment.date}}</td>
                                            <td>{{payment.amount}} {{payment.currency}}</td>
                                            <td><b-button @click="downloadReceipt(payment.id)" variant="outline-success" size="xs">{{$t('download')}}</b-button></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>

        <CommonModal ref="threeDPayModal" @bHideModalHeaderClose="closedThreeDPayModal($event)" :onHideOnlyX="true">
            <template v-slot:CommonModalTitle>
                {{ $t('pay') }}
            </template>
            <template v-slot:CommonModalContent>
                <iframe sandbox="allow-same-origin allow-top-navigation allow-forms allow-scripts" :srcdoc="paymentResult" width="100%" height="550px" class="border-0"></iframe>
            </template>
        </CommonModal>

        <CommonModal id="autoPay" :onHideOnlyX="true">
            <template v-slot:CommonModalTitle>
                AUTOPAY
            </template>
            <template v-slot:CommonModalContent>
                <div v-if="!showAutoPay">
                    <div class="row">
                        <div class="col-12 mb-3">
                            If you prefer a payment plan option, you can autopay your installments through debit and credit cards. Automatic payments are deducted from your credit/debit card on the payment days until the semester balance is paid in full.  The most apparent benefit of autopay is that you'll never miss a payment. Paying on time can save you money; when you miss a payment deadline, you must pay a late fee ($25 per credit for each late payment).
                            <br><br>
                            You can fill out the Credit/Debit Card Recurring Payment Authorization Form and send it so that we can charge your card automatically on the installment dates.
                        </div>
                        <div class="col-12">
                            <b-button variant="success btn-block" @click="selectAutoPay">I Want Autopay</b-button>
                            <b-button variant="danger btn-block" @click="cancelAutoPay">I Don't Want Autopay</b-button>
                        </div>
                    </div>
                </div>
                <div v-if="showAutoPay">
                    <ValidationObserver ref="autoPayForm">
                        <div class="row">
                            <div class="col-12">
                                <b-form-group label="Name of Student" class="position-relative">
                                    {{epaymentUser.surname}} {{epaymentUser.name}}
                                </b-form-group>
                            </div>
                            <div class="col-12">
                                <b-form-group label="Student ID Number" class="position-relative">
                                    {{epaymentUser.number}}
                                </b-form-group>
                            </div>
                            <div class="col-12">
                                <ValidationProvider name="address" rules="required" v-slot="{valid, errors}">
                                    <b-form-group label="Billing Address" class="position-relative">
                                        <b-form-input type="text"
                                                      v-model="formAutoPay.address"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <ValidationProvider name="state" rules="required" v-slot="{valid, errors}">
                                    <b-form-group label="State" class="position-relative">
                                        <CitySelectbox
                                                      v-model="formAutoPay.city_id"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </CitySelectbox>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <ValidationProvider name="city" rules="required" v-slot="{valid, errors}">
                                    <b-form-group label="City" class="position-relative">
                                        <DistrictOutlineSelectbox
                                            :city_id="formAutoPay.city_id"
                                            v-model="formAutoPay.district_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </DistrictOutlineSelectbox>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <ValidationProvider name="zip_code" rules="required" v-slot="{valid, errors}">
                                    <b-form-group label="Zip Code" class="position-relative">
                                        <b-form-input type="text"
                                                      v-model="formAutoPay.zip_code"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                      autocomplete="off"
                                        >
                                        </b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <ValidationProvider name="email" rules="required" v-slot="{valid, errors}">
                                    <b-form-group label="E-mail" class="position-relative">
                                        <b-form-input type="email"
                                                      v-model="formAutoPay.email"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                      autocomplete="off"
                                        >
                                        </b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <ValidationProvider name="phone" rules="required" v-slot="{valid, errors}">
                                    <b-form-group label="Phone Number" class="position-relative">
                                        <b-form-input type="tel"
                                                      v-model="formAutoPay.mobile_tel"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                      autocomplete="off"
                                        >
                                        </b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <ValidationProvider name="card_number" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('card_number')" class="position-relative">
                                        <b-form-input type="text"
                                                      v-model="formAutoPay.card_number"
                                                      :state="errors[0] ? false : (valid ? true : null)" v-mask="'#### #### #### ####'"
                                                      autocomplete="off"
                                        >
                                        </b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <ValidationProvider name="card_owner" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('card_owner')">
                                        <b-form-input type="text"
                                                      v-model="formAutoPay.card_holder_name"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                      autocomplate="off"
                                                      v-uppercase
                                        >
                                        </b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-4">
                                <ValidationProvider name="expiry_month" rules="required" v-slot="{valid, errors}">
                                    <fieldset class="form-group">
                                        <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">{{ $t('month') }}</legend>
                                        <multiselect
                                            :placeholder="$t('select')"
                                            v-model="formAutoPay.expiry_month"
                                            :options="paymentFormMonthOptions"
                                            :select-label="''"
                                            :selected-label="''"
                                            :deselect-label="''"
                                            :searchable="false"
                                            :allow-empty="true"
                                            :class="errors[0] ? 'is-invalid' : (valid ? 'is-valid' : null)"
                                        >
                                        </multiselect>
                                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                    </fieldset>
                                </ValidationProvider>
                            </div>
                            <div class="col-4">
                                <ValidationProvider name="expiry_year" rules="required" v-slot="{valid, errors}">
                                    <fieldset class="form-group">
                                        <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">{{ $t('year') }}</legend>
                                        <multiselect
                                            :placeholder="$t('select')"
                                            v-model="formAutoPay.expiry_year"
                                            :options="paymentFormYearOptions"
                                            :select-label="''"
                                            :selected-label="''"
                                            :deselect-label="''"
                                            :searchable="false"
                                            :class="errors[0] ? 'is-invalid' : (valid ? 'is-valid' : null)"
                                        >
                                        </multiselect>
                                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                    </fieldset>
                                </ValidationProvider>
                            </div>
                            <div class="col-4">
                                <ValidationProvider name="cvc" rules="required" v-slot="{valid, errors}">
                                    <b-form-group label="CVC">
                                        <b-form-input type="text"
                                                      v-model="formAutoPay.cvv"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                      v-mask="'###'">
                                        </b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <b-button type="button" variant="primary btn-block" @click="saveAutoPay">
                                    I Confirm Autopay
                                </b-button>
                            </div>
                        </div>

                    </ValidationObserver>
                </div>

            </template>
        </CommonModal>

        <epayment-footer></epayment-footer>
    </div>
</template>

<script>
    import EPaymentService from "@/services/EPaymentService"
    import EpaymentFooter from "./components/Footer"
    import CommonModal from "@/components/elements/CommonModal";
    import UserButton from "./components/UserButton"
    import CitySelectbox from "@/components/interactive-fields/CitySelectbox"
    import {ValidationProvider, ValidationObserver} from "vee-validate"
    import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox.vue";

    export default {
        components: {
            DistrictOutlineSelectbox,
            CitySelectbox,
            EpaymentFooter,
            CommonModal,
            UserButton,
            ValidationProvider,
            ValidationObserver,
        },
        metaInfo() {
            return {
                title: this.$t('epayment')
            }
        },
        data() {
            return {
                tabIndex: 0,
                epaymentToken: null,
                epaymentUser: {
                    number:null,
                    name:null,
                    surname:null,
                },
                debtsLoading:false,
                debts: {
                    payment_plans: {},
                    payments: {},
                    virtual_pos: {},
                },
                allowPaypal:false,
                advancePaymentMethod: null,
                oneShotPaymentMethod: null,

                // PAYMENT
                paymentDataType: null,
                paymentDebtId: null,
                paymentType: null,
                paymentDebt: {},

                paymentFormMonthOptions: [],
                paymentFormYearOptions: [],
                paymentFormInstallmentOptions: [],

                paymentFormAmountIsReadOnly: true,
                paymentFormAmount: 0,
                paymentFormShowAmount: 0,
                paymentFormRemainingAmount: 0,
                paymentFormSumAmount: 0,
                paymentFormMaxAmount: 0,
                paymentFormPrice: 0,

                paymentFormCardHolderName: null,
                paymentFormCreditCardNumber: '',
                paymentFormCreditCardLogo: null,
                paymentFormExpiryMonth: null,
                paymentFormExpiryYear: null,
                paymentFormCvv: null,
                paymentFormBankCode: null,
                paymentFormInstallment: 1,
                paymentFormExtraInstallment: null,
                paymentFormInstallmentDisabled: true,
                paymentFormCheck: '',

                paymentResult: '',
                paymentOrderId: null,
                paymentStatus: null,

                commonModalModeSize: 'lg',
                commonModalMode: '',

                formAutoPay:{
                    card_number: null,
                    expiry_year: null,
                    expiry_month: null,
                    cvv: null,
                    card_holder_name: null,
                    email: null,
                    mobile_tel: null,
                },
                showAutoPay:false,
                sendAutoPay:false,
                cart:null
            }
        },
        created() {
            this.$store.dispatch('epayment/initAuth')
                .then(() => {
                    this.epaymentToken = this.$store.getters['epayment/getEpaymentToken'];
                    this.epaymentUser = this.$store.getters['epayment/getEpaymentUser'];
                })
                .then(() => {
                    this.getDebts().then(r=>{
                        this.getCart()
                    });
                });

            const year = new Date().getFullYear();
            for (var i = 0; i <= 12; i++) {
                if (i > 0) {
                    this.paymentFormMonthOptions.push(i);
                }
                if (i < 9) {
                    this.paymentFormYearOptions.push(year + i);
                }
            }
        },
        methods: {
            clearDebt() {
                this.paymentDataType = null;
                this.paymentDebtId = null;
                this.paymentType = null;
                this.paymentDebt = {};
                this.paymentOrderId = null;
                this.cart = null;
                this.getDebts().then(r=>{
                    this.getCart();
                });
            },
            modifyProgramStatusFields(paymentType) {
                return [
                    {key: 'payments_sum_amount', label: this.$t('amount_paid').toUpper(), thClass: 'text-center', tdClass: 'text-center'},
                    {key: 'debt', label: this.$t('remaining_payment_amount').toUpper(), thClass: 'text-center', tdClass: 'text-center'},
                    {key: 'total_price', label: this.$t('total_amount').toUpper(), thClass: 'text-center', tdClass: 'text-center'}
                ]
            },
            modifyProgramStatusItems(paymentType, item) {
                return [
                    {
                        total_price: paymentType == 'advance' ? item.cash_price: item.price,
                        payments_sum_amount: item.payments_sum_amount,
                        debt: paymentType == 'advance' ? item.cash_debt : item.debt
                    }
                ]
            },
            getDebts() {
                this.debtsLoading = true;
                return EPaymentService.debts(this.epaymentToken)
                                      .then(response => {
                                          this.debts = response.data.data;
                                      })
                                      .then(() => {

                                          if (this.debts.virtual_pos) {
                                              this.debts.virtual_pos.forEach((elem) => {
                                                  if(elem.code=='paypal') this.allowPaypal=true;
                                                  if (elem.is_default) this.advancePaymentMethod = elem;
                                                  if (elem.is_default_for_one_shot) this.oneShotPaymentMethod = elem;
                                              })
                                          }
                                          this.tabIndex=0;
                                      })
                                      .catch(error => {
                                          this.$toast.error(this.$t('api.' + error.data.message));
                                      })
                                      .finally(() =>{
                                          this.debtsLoading = false;
                                      })
            },
            getDebt(uuid) {
                return EPaymentService.debt(this.epaymentToken, uuid)
                                      .then(response => {
                                          if (this.paymentDataType == 'payment_plan') {
                                              this.paymentDebt = response.data.data.payment_plan;
                                          }
                                          else if (this.paymentDataType == 'payment') {
                                              this.paymentDebt = response.data.data.payment;
                                          }
                                          else if (this.paymentDataType == 'housing') {
                                              this.paymentDebt = response.data.data.housing;
                                          }
                                          else {
                                              this.paymentDebt = response.data.data.other_payment;
                                          }
                                      })
                                      .catch(error => {
                                          this.$toast.error(this.$t('api.' + error.data.message));
                                      });
            },
            getPosImage(code) {
                if (code == 'vakifbank') {
                    return require('@/assets/img/bank/world.svg');
                }
                else if (code == 'garanti') {
                    return require('@/assets/img/bank/bonus.svg');
                }
                else if (code == 'ziraatbank') {
                    return require('@/assets/img/bank/bankkart.svg');
                }
                else if (code == 'halkbank') {
                    return require('@/assets/img/bank/paraf.svg');
                }
                else if (code == 'isbank') {
                    return require('@/assets/img/bank/maximum.svg');
                }
                else if (code == 'akbank') {
                    return require('@/assets/img/bank/axess.svg');
                }
            },
            paymentFormAmountCalculate() {
                this.paymentFormShowAmount = this.paymentFormAmount
                this.paymentFormRemainingAmount = this.paymentFormMaxAmount - this.paymentFormAmount;
            },
            paymentSetVars(dataType, uuid, paymentType) {
                this.paymentDataType = dataType;
                this.paymentDebtId = uuid;
                this.paymentType = paymentType;

                this.$refs.payForm.reset();
                this.loadDept();
            },
            isInTheCart(uuid) {
                for(let i=0;i<this.cart.items.length;i++){
                    let item=this.cart.items[i];
                    if(item.uuid===uuid){
                        return true;
                    }
                }
                return false;
            },
            addToCart(uuid){
                EPaymentService.addToCart(this.epaymentToken, {uuid: uuid}).then(response=>{
                    this.updateCart(response.data.data);
                }).catch(e=>{
                    this.showErrors(e);
                });
            },
            deleteFromCart(uuid){
                EPaymentService.deleteFromCart(this.epaymentToken, uuid).then(response=>{
                    this.updateCart(response.data.data);
                }).catch(e=>{
                    this.showErrors(e);
                });
            },
            getCart(){
                return EPaymentService.getCart(this.epaymentToken).then(response=>{
                    this.updateCart(response.data.data);
                }).catch(e=>{
                    this.showErrors(e);
                });
            },
            updateCart(data){
                this.cart=data;
                if(data.items.length>0){
                    this.paymentDataType='cart';
                    this.paymentFormPrice = data.total
                    this.paymentFormAmount = data.total
                    this.paymentFormSumAmount = 0
                    this.paymentFormMaxAmount = data.total
                    this.paymentFormRemainingAmount = data.total
                    this.paymentDebtId = 'cart'
                    this.paymentDebt = {currency:'USD'}
                    this.paymentFormAmountIsReadOnly = true;
                    this.paymentFormAmountCalculate()
                }
                else {
                    this.paymentDataType=null;
                    this.paymentDebtId=null;
                }
            },
            loadDept(){
                this.paymentDebt = {};
                this.getDebt(this.paymentDebtId)
                    .then(response => {
                        this.paymentFormAmount = 0;
                        this.paymentFormSumAmount = 0;
                        this.paymentFormMaxAmount = 0;

                        this.paymentFormCardHolderName = null;
                        this.paymentFormCreditCardNumber = '';
                        this.paymentFormExpiryMonth = null;
                        this.paymentFormExpiryYear = null;
                        this.paymentFormCvv = null;
                        this.paymentFormBankCode = null;
                        this.paymentFormInstallment = 1;
                        this.paymentFormInstallmentDisabled = true;
                        this.paymentFormCheck = '';
                        this.tabIndex = 1;

                        if(this.paymentDataType == 'housing'){
                            this.paymentFormInstallmentOptions = [];
                            this.paymentFormPrice = this.paymentDebt.amount
                            this.paymentFormAmount = this.paymentDebt.amount
                            this.paymentFormSumAmount = 0;
                            this.paymentFormMaxAmount = this.paymentDebt.amount
                        }
                        else if (this.paymentDataType == 'payment_plan') {
                            this.paymentFormInstallmentOptions = [1];
                            this.paymentFormInstallment = 1;
                            this.paymentFormAmount = this.paymentDebt.cash_debt;
                            this.paymentFormPrice = this.paymentDebt.cash_price;
                            this.paymentFormSumAmount = this.paymentDebt.payments_sum_amount ? this.paymentDebt.payments_sum_amount:0;
                            this.paymentFormMaxAmount = this.paymentFormAmount
                        }
                        else if (this.paymentDataType == 'payment') {
                            this.paymentFormInstallmentOptions = [1];
                            this.paymentFormInstallment = 1;
                            this.paymentFormAmount = this.paymentDebt.amount;
                            this.paymentFormPrice = this.paymentDebt.amount;
                            this.paymentFormSumAmount = this.paymentDebt.payments_sum_amount ? this.paymentDebt.payments_sum_amount:0;
                            this.paymentFormMaxAmount = this.paymentFormAmount
                        }
                        else if (this.paymentType == 'installment') {
                            this.paymentFormInstallmentOptions = [];
                            this.paymentFormPrice = this.paymentDebt.price
                            this.paymentFormAmount = this.paymentDebt.debt
                            this.paymentFormSumAmount = this.paymentDebt.payments_sum_amount ? this.paymentDebt.payments_sum_amount:0;
                            this.paymentFormMaxAmount = this.paymentDebt.debt
                        }
                        if((this.paymentDataType == 'payment' || this.paymentDataType == 'other_payment') && this.paymentDebt.is_paid){
                            this.paymentFormAmount=0;
                            this.paymentFormMaxAmount=0;
                            this.paymentFormSumAmount=this.paymentDebt.amount;
                        }

                        this.paymentFormAmountIsReadOnly = true;
                        if(this.paymentDataType == 'payment_plan') {
                            this.paymentFormAmountIsReadOnly = false;
                        }
                        this.paymentFormAmount=parseFloat(this.paymentFormAmount);
                        if(parseFloat(this.paymentFormAmount)===0 && parseFloat(this.paymentFormSumAmount)>0){
                            setTimeout(()=>{
                                this.clearDebt();
                            }, 10000);
                        }
                    })
                    .finally(()=> {
                        this.paymentFormAmountCalculate();
                    })
            },
            paymentFormInstallmentCustomLabel(value) {
                if (value == 1) {
                    return this.$t('advance_payment');
                } else {
                    return value +(this.paymentFormExtraInstallment>0?'+'+this.paymentFormExtraInstallment:'')+' ' + this.$t('installment');
                }
            },
            async pay(reTry=false) {
                const isValid = await this.$refs.payForm.validate();
                if (isValid) {
                    if(!reTry && this.paymentDataType == 'payment' && this.paymentDebt.allow_autopay){
                        this.$bvModal.show('autoPay');
                        return;
                    }
                    const creditCardNumber = this.paymentFormCreditCardNumber;
                    const formData = {
                        bank_code: this.paymentFormBankCode,
                        amount: this.paymentFormAmount,
                        card_holder_name: this.paymentFormCardHolderName,
                        credit_card_number: creditCardNumber.replace(/[^0-9]/g, ""),
                        expiry_month: this.paymentFormExpiryMonth,
                        expiry_year: this.paymentFormExpiryYear,
                        installment: this.paymentFormInstallment,
                        cvv: this.paymentFormCvv,
                        is_cash: this.paymentType == 'advance' ? 1 : 0,
                        address: this.formAutoPay.address,
                        city_id: this.formAutoPay.city_id,
                        district_id: this.formAutoPay.district_id,
                        zip_code: this.formAutoPay.zip_code,
                        email: this.formAutoPay.email,
                        mobile_tel: this.formAutoPay.mobile_tel,
                        want_autopay: this.sendAutoPay?1:0
                    };
                    return EPaymentService.pay(this.epaymentToken, this.paymentDebtId, formData)
                                          .then(response => {
                                              const data = response.data.data;

                                              this.paymentOrderId = data.order_id;

                                              if(data.is_successful===false){
                                                  this.$toast.error(this.$t('api.' + data.message));
                                                  return;
                                              }
                                              else if(data.is_successful===true) {
                                                  this.$refs.payForm.reset();
                                                  this.closedThreeDPayModal();
                                                  return;
                                              }

                                              this.paymentResult = data.html;

                                              this.$refs.threeDPayModal.$refs.commonModal.show()
                                          })
                                          .catch(error => {
                                              if (error.status == 422) {
                                                  if (error.data.errors.amount) {
                                                      this.$refs.payForm.errors.payment_total.push(error.data.errors.amount);
                                                  }
                                                  if (error.data.errors.card_holder_name) {
                                                      this.$refs.payForm.errors.card_owner.push(error.data.errors.card_holder_name);
                                                  }
                                                  if (error.data.errors.credit_card_number) {
                                                      this.$refs.payForm.errors.card_number.push(error.data.errors.credit_card_number);
                                                  }
                                                  if (error.data.errors.expiry_month) {
                                                      this.$refs.payForm.errors.expiry_month.push(error.data.errors.expiry_month);
                                                  }
                                                  if (error.data.errors.expiry_year) {
                                                      this.$refs.payForm.errors.expiry_year.push(error.data.errors.expiry_year);
                                                  }
                                                  if (error.data.errors.installment) {
                                                      this.$refs.payForm.errors.installment.push(error.data.errors.installment);
                                                  }
                                                  if (error.data.errors.cvv) {
                                                      this.$refs.payForm.errors.cvc.push(error.data.errors.cvv);
                                                  }
                                              }
                                              else if (error.status == 406) {
                                                  this.showErrors(error);
                                              }
                                          });
                }
            },
            async paypal() {
                const isValid = this.paymentFormAmount>0;
                if (isValid) {
                    const formData = {
                        bank_code: 'paypal',
                        amount: this.paymentFormAmount,
                        is_cash: 0,
                        installment: 0
                    };
                    return EPaymentService.pay(this.epaymentToken, this.paymentDebtId, formData)
                        .then(response => {
                            const data = response.data.data;

                            this.paymentOrderId = data.order_id;

                            if(data.is_successful===false){
                                this.$toast.error(this.$t('api.' + data.message));
                                return;
                            }

                            this.paymentResult = data.html;

                            this.$refs.threeDPayModal.$refs.commonModal.show()
                        })
                        .catch(error => {
                            this.showErrors(error);
                        });
                }
            },
            showModal(mode) {
                this.commonModalMode = mode;
                this.$refs.paymentModal.$refs.commonModal.show()
            },
            closedThreeDPayModal() {
                if(this.paymentOrderId){
                    return EPaymentService.paymentStatus(this.epaymentToken, this.paymentOrderId)
                                          .then(response => {
                                              this.paymentStatus = response.data.data.status;
                                              this.paymentStatusMessage = response.data.data.message;
                                              if(this.paymentStatus=='approved'){
                                                  this.$refs.payForm.reset();
                                                  this.tabIndex=0;
                                                  setTimeout(()=>{
                                                      this.clearDebt();
                                                  }, 1000);
                                                  this.$toast.success(this.$t('your_payment_transaction_has_been_successfully_completed'));
                                              }
                                              else if(this.paymentStatus=='cancelled'){
                                                  this.$toast.error(this.$t('your_payment_transaction_has_been_cancelled'));
                                              }
                                              else if(this.paymentStatus=='declined'){
                                                  this.$toast.error(this.$t('something_went_wrong')+' '+this.$t('please_try_again'));
                                              }
                                          })
                                          .catch(error => {
                                              this.paymentStatus = 'error';
                                          });
                }
            },
            logout() {
                this.$router.push('epayment/logout')
            },
            downloadReceipt(id){
                EPaymentService.downloadReceipt(this.epaymentToken, id)
                    .then(response=>{
                        this._downloadFile(response, id+'.pdf');
                    })
                    .catch(e=>{
                        this.showErrors(e)
                    });
            },
            selectAutoPay(){
                this.formAutoPay.card_number=this.paymentFormCreditCardNumber;
                this.formAutoPay.card_holder_name=this.paymentFormCardHolderName;
                this.formAutoPay.cvv=this.paymentFormCvv;
                this.formAutoPay.expiry_month=this.paymentFormExpiryMonth;
                this.formAutoPay.expiry_year=this.paymentFormExpiryYear;
                this.showAutoPay=true;
            },
            cancelAutoPay(){
                this.$bvModal.hide('autoPay');
                this.sendAutoPay=false;
                this.showAutoPay=false;
                this.pay(true);
            },
            async saveAutoPay(){
                const isValid = await this.$refs.autoPayForm.validate();
                if(isValid){
                    this.sendAutoPay=true;
                    this.showAutoPay=false;
                    this.$bvModal.hide('autoPay');
                    await this.pay(true);
                }
            }
        },
        watch: {
            paymentFormCreditCardNumber(value, oldValue) {
                if (value!='' && value.length > 6) {
                    let value6Char = value.substring(0, 7).replaceAll(' ','');
                    let oldValue6Char = oldValue.substring(0, 7).replaceAll(' ','');
                    if(value6Char != oldValue6Char) {
                        this.paymentFormCreditCardLogo = null;
                        if(value6Char.length!=6){
                            return;
                        }
                        return EPaymentService.virtualPosBin(this.epaymentToken, value6Char)
                            .then(response => {
                                var bank = response.data.data;

                                this.paymentFormBankCode = null;
                                this.paymentFormInstallmentOptions = null;
                                this.paymentFormExtraInstallment = null;

                                // Pos Check
                                this.debts.virtual_pos.forEach((elem) => {
                                    if(elem.code==bank){
                                        this.paymentFormBankCode = elem.code;

                                        if(this.paymentType=='installment'){
                                            let installment = elem.installments;
                                            if(installment && installment.length==1){
                                                this.paymentFormInstallment = installment[0];
                                                if(elem.extra_installment>0){
                                                    this.paymentFormExtraInstallment=elem.extra_installment;
                                                }
                                            }
                                        }
                                        else{
                                            let installment = [1];
                                            this.paymentFormInstallment = installment[0];
                                        }
                                        this.paymentFormInstallmentOptions = this.paymentType=='installment' ? elem.installments:[1];
                                    }
                                })

                                // Pos var/yok ise?
                                if (this.paymentFormBankCode) {
                                    this.paymentFormCreditCardLogo = this.paymentFormBankCode;
                                    if (this.paymentType == 'advance') {
                                        if(this.oneShotPaymentMethod){
                                            this.paymentFormBankCode = this.oneShotPaymentMethod.code;
                                        }
                                        this.paymentFormInstallment = 1;
                                    }

                                    this.paymentFormInstallmentDisabled = false;
                                }
                                else {
                                    if (this.paymentType == 'advance') {
                                        if(this.advancePaymentMethod){
                                            this.paymentFormBankCode = this.advancePaymentMethod.code;
                                            this.paymentFormInstallment = 1;
                                            this.paymentFormInstallmentDisabled = false;
                                        }
                                        else {
                                            this.paymentFormInstallmentDisabled = true;
                                        }
                                    }
                                    else if (this.paymentType == 'installment') {
                                        this.$toast.error(this.$t('no_installment_message'));
                                        this.paymentFormInstallmentDisabled = true;
                                    }
                                }
                            })
                            .catch(error => {
                                this.paymentFormBankCode = this.advancePaymentMethod.code;
                                this.paymentFormInstallment = 1;
                                this.paymentFormInstallmentDisabled = false;
                            })
                    }
                }
                else {
                    this.paymentFormBankCode = null;
                    this.paymentFormInstallmentOptions = [];
                    this.paymentFormInstallment = null;
                    this.paymentFormInstallmentDisabled = true;
                    this.paymentFormCreditCardLogo = null;
                }
            }
        }
    }
</script>
